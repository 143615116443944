import React from 'react'
import {Box, Container, makeStyles} from '@material-ui/core'
import IGLogo from '../images/svg/ig.svg'
import LinkedinLogo from '../images/svg/linkedin.svg'
import EmailLogo from '../images/svg/email.svg'
import Img from 'gatsby-image'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames'

const useStyles = makeStyles({
  h1: {
    fontSize: 32,
    fontWeight: '100'
  },
  body: {
    fontSize: 20,
    lineHeight: 1.5,
    fontWeight: 200

  },
  copyright: {
    fontSize: 12,
    color: '#888',
  },
  container: {
    backgroundColor: '#1b1d22',
    color: '#fff',
  },
  socialIcons: {
    height: 32,
    width: 32,
    color: '#999999',
    '&:hover': {
      color: 'white'
    }
  },
  iconMargin: {
    marginRight: 50,
  },
  profileImage: {
    height: '80px',
    width: '80px',
    objectFit: 'cover',
    borderRadius: '40px',
    marginBottom: '8px',

  },
  hyperlink: {
    fontWeight:'400',
    textDecoration: 'none',
    color: 'inherit'
  }
})


const HomePage = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      className={classes.container}
      height="100vh" 
      width="100%" 
      display="flex" 
      alignItems="left"
      justifyContent={matches ? "center" : "start"}
      flexDirection="column"
      
    >
      <Box m={4}>
        <Container>
          <Box display="flex" alignItems="center" flexDirection="column" mb={3}>
            <Box>
              <Img className={classes.profileImage} fluid={props.data.AmanProfile.childImageSharp.fluid}/>
            </Box>
            <Box ml={4} className={classes.h1}>
              Hey! I'm <span style={{fontWeight: 400}}>Aman</span>. I'm a software engineer.
            </Box>
  
          </Box>
          <Box className={classes.body} mb={2}>
            When I'm not building software to improve student financial success at CampusLogic, I can be found working on online ventures like <a href="https://specranks.com" className={classes.hyperlink}>SpecRanks</a>, or practicing on my saxophone.
          </Box>
          <br/><br/>
          <Box mb={8} display="flex" alignItems="center" justifyContent="center" flexDirection="row">
            <a href="https://www.linkedin.com/in/amanabdulalim/" rel="nofollow" target="_blank">
              <LinkedinLogo className={classNames(classes.socialIcons, classes.iconMargin)} />
            </a>
            <a href="https://www.instagram.com/amanabdulalim/" rel="nofollow" target="_blank">
              <IGLogo className={classNames(classes.socialIcons, classes.iconMargin)}/>
            </a>
            <a href="mailto:contact@amanabdulalim.com" rel="nofollow" target="_blank">
              <EmailLogo className={classes.socialIcons}/>
            </a>
          </Box>
          <Box className={classes.copyright} display="flex" justifyContent="center">
            <span>© Copyright {new Date().getFullYear()} <b>Aman Abdulalim</b> · All Rights Reserved.</span>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default HomePage

export const query = graphql`
  query {
    AmanProfile: file(relativePath: { eq: "AmanProfile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`